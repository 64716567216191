import React from 'react';
import logo from '../ginkgo.png';
import '../App.css';
import axios from 'axios';
import { baseURL } from '../API/url';
import moment, { now } from 'moment';
import { Form, DatePicker, Button, Typography, message, Radio, Input, Space, Descriptions, Modal, Table, Select } from 'antd';
import { withRouter } from "react-router";
import { Certificate } from 'crypto';
import { transpileModule } from 'typescript';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';
const DB_dateFormat = 'YYYY-MM-DD';


class Portfolio extends React.Component<any,any> {
	constructor(props:any) {
		super(props);
		
		this.state = { 
				transactionData: [],
				certData: [],
				certDataAdvisor: [],
				transactionStartDate: moment().dayOfYear(1).format(dateFormat),
				transactionEndDate: moment().format(dateFormat),
				certStartDate: moment().dayOfYear(1).subtract(4, 'years').format(dateFormat),
				certEndDate: moment().dayOfYear(365).format(dateFormat),
				isAuthenticated: props.isAuthenticated,
				partnersData: [],
				currentAccountRecId: '',
				totalAccountValue: [],
				totalAccountsValue: 0,
				totalAccountTrailerFees: 0,
				totalAccountsTrailerFees: 0,
				certTrailerFees: [],
				displayAccountNum: '',
				displayAdvisorType: '',
				transactionProductId: '',
				certificateProductId: '',
				certificateProductName: '',
				partner: {},
				certSub_total: '',
				selectedYear: moment().dayOfYear(1).format(dateFormat),
				subTotalFeesOnYears: [{year: moment().subtract(9, "years").year(), total: 0}, {year: moment().subtract(8, "years").year(), total: 0}, {year: moment().subtract(7, "years").year(), total: 0}, {year: moment().subtract(6, "years").year(), total: 0}, {year: moment().subtract(5, "years").year(), total: 0}, {year: moment().subtract(4, "years").year(), total: 0},{year: moment().subtract(3, "years").year(), total: 0}, {year: moment().subtract(2, "years").year(), total: 0}, {year: moment().subtract(1, "years").year(), total: 0}, {year: moment().year(), total: 0}],
				selectedYearCommTotal: [0,0,0,0,0],
/* 				currentMonthFeesTotal: 0,
 */				currentMonthFeesTotal: '',
				currentYear: moment().year(),
				currentMonth: moment().month(),
				isModalOpen:false,
				selectedPartnerSin:'',
				clientListData: [],
				clientList: [],
				advisorId: '',
				totalClient: 0,
				totalAsset: 0,
				clientTotalValue: 0,
			//	selectedCommMonth: '2',
				selectedCommMonth: moment().subtract(1, 'months').month(),
				searchText: '',
		};
	}
	
	componentDidMount = async() => {
		
		if(!this.state.isAuthenticated) {
			this.props.history.push('/login');
			return;
		}

		try {
			while (this.state.isAuthenticated[1] === undefined) {
				await this.delay(100)
			}
			let partnersQuery = ''	
			const advisor = await axios.get(baseURL + '/user/'+this.state.isAuthenticated[1])  // user email
			this.setState({advisorId: advisor.data.id})
			const clientFirstTin = await axios.get(baseURL + '/partnertin?advisorId='+this.state.advisorId)
			partnersQuery = '?advisorId='+ this.state.advisorId + '&partnerSin=' + clientFirstTin.data
			const partners = await axios.get(baseURL+"/advisoraccounts"+partnersQuery)
 			await this.setState({selectedYear: moment().dayOfYear(1).format(dateFormat)})
			await this.sumFeesTotalOnYears(partners.data[0].recId, this.state.certificateProductName)
			await this.getAccountTrailerFeesTotal(partners.data[0].recId, '')
			await this.sumFeesTotalOnYears(partners.data[0].recId, this.state.certificateProductName)
			
 			this.setState({partner: partners.data[0], partnersData: partners.data, currentAccountRecId: partners.data[0].recId, displayAccountNum: partners.data[0].account, displayAdvisorType: partners.data[0].advisorType})

			const commQuery = '?partnerId='+partners.data[0].recId+'&productName='+''
			let cert_data = await axios.get(baseURL+"/commissiondetails"+commQuery)
			this.setState({certData: cert_data.data, selectedYear: moment().dayOfYear(1).format(dateFormat) });
			await this.countTotalAccountsValue(partners.data.map((partnerData:any)=>(partnerData.recId)))
			const advisorData = await axios.get(baseURL+'/advisorcommission?email=' + this.state.isAuthenticated[1] + '&theMonth=' + this.state.selectedCommMonth + '&theYear=2024') //get emd table
			await this.setState({ certDataAdvisor: advisorData.data})
			const clients = await axios.get(baseURL+'/clientList?user='+ this.state.advisorId )
			await this.setState({clientListData: clients.data.partners})
			await this.setState({clientTotalValue: clients.data.clientTotalValue})
			const clientsTotalAsset = await axios.get(baseURL+'/getclientasset?user='+ this.state.advisorId)
			await this.setState({totalAsset: clientsTotalAsset.data.total})
			const data: { key: number; firstName: string; lastName: string; emailAddress: string; phone: string; totalValue: string; totalDrip: string; tin: string }[] = [];

	if (this.state.clientListData.length !== 0) {
		this.setState({ totalClient: this.state.clientListData.length });
		for (let i = 0; i < this.state.clientListData.length; i++) {
			let clientTotalValue = await axios.get(
				baseURL + "/getclienttotalvalue?tin=" + this.state.clientListData[i].tin
			);
			if ( this.state.clientListData[i].tin!==null )  {
			 if (parseFloat(clientTotalValue.data.total.replace(/[$,]/g, '')) > 0.00 ) {
				// active client
			  data.push({
				key: i,
				firstName: this.state.clientListData[i].firstName,
				lastName: this.state.clientListData[i].lastName,
				emailAddress: this.state.clientListData[i].emailAddress || '',
				phone: this.state.clientListData[i].phoneHome || '',
				totalValue: clientTotalValue.data.total, // Access the 'total' property from the 'data' property of the response
				totalDrip: clientTotalValue.data.totalDrip, // Access the 'total' property from the 'data' property of the response
				tin: this.state.clientListData[i].tin || '',
			})
		}};
		}
	}
	this.setState({clientList: data})


	const totalValue = await axios.get(baseURL+'/advisorcommissiontotalbymonth?email=' + this.state.isAuthenticated[1] + '&theMonth=' + this.state.selectedCommMonth + '&theYear=2024')
			await this.setState({currentMonthFeesTotal: totalValue.data})
		} catch (error) {
			alert('No Data Record Was Found');
			this.props.history.push('/profile');
			await this.render();
			return;
		}	
	}

	getCertsData = async(partnerId:any, productName:any) => {
		const commQuery = '?partnerId='+partnerId+'&productName='+productName
		let cert_data = await axios.get(baseURL+"/commissiondetails"+commQuery)
		this.setState({ certData: cert_data.data});
	}

	getCertsDataAdvisor = async(advisorId:any) => {
		const commQuery = '?advisorId='+this.state.advisorId+'&theMonth='+ this.state.selectedCommMonth  // 0: Jan, 1: Feb, 2: Mar
		let cert_data = await axios.get(baseURL+"/advisorcommissiondetails"+commQuery)
		return cert_data.data
	}

	getAccountTrailerFeesTotal = async(recId:any, productName:any) => {
		const commQuery = '?partnerId='+recId+'&productName='+productName
		const accountTrailerFees = await axios.get(baseURL+"/trailerfees"+commQuery)
		if (accountTrailerFees.data.yearstotal===null) {
			return 0
		}
		else {
			return parseFloat(accountTrailerFees.data.yearstotal.replace(/[^0-9.-]+/g,""))  // money type convert to number
		}
	}

	copyYearsTotal = async(totalArray:any) => {
		const  fiveYearsTotalArray:any = totalArray.filter((item:any) => item.year >= moment(this.state.selectedYear).subtract(4, "years").year() && item.year <= moment(this.state.selectedYear).year())
 		const fiveYearsTotalValue= fiveYearsTotalArray.map((item:any) => item.total)
		await this.setState({selectedYearCommTotal: fiveYearsTotalValue })
	}

	sumFeesTotalOnYears = async(recId:any, productName:any) => {
	let totalFeesOnYear = this.state.subTotalFeesOnYears
	await totalFeesOnYear.map(async (item:any) => {
 	 let commQuery = '?partnerId='+recId+'&year=yearB'+(Number(moment().year())-Number(item.year)).toString()+'&productName='+productName
	 let commission = await axios.get(baseURL+"/commission"+commQuery)
	 item.total = commission.data.total
	})
	await this.setState({subTotalFeesOnYears: totalFeesOnYear})
	await this.copyYearsTotal(totalFeesOnYear)
	await this.delay(500)
}

	getTransactionData = async(transactionStartDate: string, transactionEndDate: string, recId: string, product: string) => {
		const transactionQuery = '?transactionStartDate='+transactionStartDate+'&transactionEndDate='+transactionEndDate+'&recId='+recId+'&productId='+product;
		const transaction_data = await axios.get(baseURL+"/transactions"+transactionQuery);
		return transaction_data
	}

	getTotalPortfolioData = async(recId: string) => {
		const total_trans = await this.getTransactionData('', '', recId,'');
		if (!total_trans || total_trans.data.length === 0) {
			return {total_share_S1_balance:0, total_share_S2_balance:0, endingCapital:0, totalAccountTrailerFees:0, partnerInfo:recId};
		}
		const total_trans_data = total_trans.data;

		let total_share_S1_balance = 0;
		let total_share_S2_balance = 0;
		let endingCapital = 0;
		total_trans_data.forEach((tran:any)=>{
			if (tran.partnership.recId==='253a1dc7-3bdc-40d9-98c9-75ca619fb035') {
			total_share_S1_balance += Number(tran.shares);
			endingCapital +=  Number(tran.shares) * tran.partnership.fixedShareValue
			}
			if (tran.partnership.recId==='4097eb52-bcd6-4f56-b470-5b44e2bf6c54') {
				total_share_S2_balance += Number(tran.shares);
				endingCapital +=  Number(tran.shares) * tran.partnership.fixedShareValue
				}
		})
		const partnerInfo = this.state.partnersData.filter((partner:any)=>partner.recId === recId);
		const totalFees = await this.getAccountTrailerFeesTotal(recId, '')
		return {total_share_S1_balance, total_share_S2_balance, endingCapital, totalAccountTrailerFees: totalFees, partnerInfo:partnerInfo[0]};
	}

	getAccountPortfolio = async(recId: string) => {
		const partnerInfo = this.state.partnersData.filter((partner:any)=>partner.recId === recId);
		const totalFees = await this.getAccountTrailerFeesTotal(recId, '')
		const portfolio = await axios.get(baseURL+'/partnerportfolio?recId='+recId)	
		return {total_share_S1_balance:portfolio.data.partner.shareS1, total_share_S2_balance:portfolio.data.partner.shareS2, accountTotal:parseFloat(portfolio.data.partner.totalValue.replace(/[^0-9.-]+/g,"")), totalAccountTrailerFees: totalFees, partnerInfo:partnerInfo[0]}	
	}
	countTotalAccountsTrailerFees = async(recIds:string[]) => {
		const totalAccountTrailerFeesArray = await Promise.all(recIds.map(async (recId:string) => {
			return await this.getAccountTrailerFeesTotal(recId, '')
		}));
		let allAccountsTrailerFees = 0;
		totalAccountTrailerFeesArray.forEach((value)=>{
			allAccountsTrailerFees += value
		})
		await this.setState({totalAccountsTrailerFees: allAccountsTrailerFees})
	}

	countTotalAccountsValue = async(recIds:string[]) => {
		const totalAccountValueArray = await Promise.all(recIds.map(async (recId:string) => {
			return await this.getAccountPortfolio(recId)
		}));
		let totalAccountsValue = 0;
		 let allAccountsTrailerFees = 0
		totalAccountValueArray.forEach((value)=>{


			allAccountsTrailerFees += value.totalAccountTrailerFees  
			if(value.accountTotal){
				totalAccountsValue += value.accountTotal;
			}
		})
		await this.setState({totalAccountsValue: totalAccountsValue.toFixed(2), totalAccountsTrailerFees: allAccountsTrailerFees.toFixed(2)});
		await this.setState({totalAccountValue: totalAccountValueArray})
	}

	rangeConfig = {
		rules: [{ type: 'array' as const, required: true, message: 'Please select time!' }],
	};


	onClickCertYear = async (e:any) => {
		switch (e.target.value) {
			case 'firstYear':
				await this.setState({ certStartDate: moment().dayOfYear(1).subtract(13, 'years').format('MM-DD-YYYY'), certEndDate: moment().dayOfYear(365).subtract(5, 'years').format('MM-DD-YYYY'), selectedYear: moment().dayOfYear(1).subtract(4, 'years').format(dateFormat) });
				break;
			case 'secondYears':
				await this.setState({ certStartDate: moment().dayOfYear(1).subtract(12, 'years').format('MM-DD-YYYY'), certEndDate: moment().dayOfYear(365).subtract(4, 'years').format('MM-DD-YYYY'), selectedYear: moment().dayOfYear(1).subtract(3, 'years').format(dateFormat) });
				break;
			case 'thirdYears':
				await this.setState({ certStartDate: moment().dayOfYear(1).subtract(11, 'years').format('MM-DD-YYYY'), certEndDate: moment().dayOfYear(365).subtract(3, 'years').format('MM-DD-YYYY'), selectedYear: moment().dayOfYear(1).subtract(2, 'years').format(dateFormat) });
				break;
			case 'fourthYears':
				await this.setState({ certStartDate: moment().dayOfYear(1).subtract(10, 'years').format('MM-DD-YYYY'), certEndDate: moment().dayOfYear(365).subtract(2, 'years').format('MM-DD-YYYY'), selectedYear: moment().dayOfYear(1).subtract(1, 'years').format(dateFormat) });
				break;
			case 'fifthYears':
				await this.setState({ certStartDate: moment().dayOfYear(1).subtract(9, 'years').format('MM-DD-YYYY'), certEndDate: moment().subtract(1, 'years').format('MM-DD-YYYY'), selectedYear: moment().dayOfYear(1).format(dateFormat) });
//				await this.setState({ certStartDate: moment('01-01-2012').format('MM-DD-YYYY'), certEndDate: moment().format('MM-DD-YYYY') });
				break;			
			case 'GINKGO-S1':
				await this.setState({ certificateProductId: '253a1dc7-3bdc-40d9-98c9-75ca619fb035', certificateProductName: 'GINKGO-S1' });
				break;
			case 'GINKGO-S2':
				await this.setState({ certificateProductId: '4097eb52-bcd6-4f56-b470-5b44e2bf6c54', certificateProductName: 'GINKGO-S2'});
				break;
			case 'All':
				await this.setState({ certificateProductId: '', certificateProductName: ''});
				break;
	}
		await this.getCertsData(this.state.currentAccountRecId, this.state.certificateProductName);
		await this.sumFeesTotalOnYears(this.state.currentAccountRecId, this.state.certificateProductName)
		await this.sumFeesTotalOnYears(this.state.currentAccountRecId, this.state.certificateProductName)
		await this.delay(500)
		await this.render();
		
	};
	
	delay = async (milliseconds:any) => {
		return new Promise(resolve => {
		setTimeout(resolve, milliseconds);
		});
	}

	handleTagChange = async (e: any) => {
		
		let currentAccountRecId = e.target.id;
		await this.setState( (prevState:any)=> ({ ...prevState, currentAccountRecId }));
		const currentPartnerInfo = this.state.partnersData.filter((partner:any)=>partner.recId === currentAccountRecId);
		await this.getCertsData(currentAccountRecId, this.state.certificateProductName)
		await this.setState({ displayAccountNum: currentPartnerInfo[0].account, displayAdvisorType: currentPartnerInfo[0].advisorType, partner: currentPartnerInfo[0] });
		await this.sumFeesTotalOnYears(currentAccountRecId, this.state.certificateProductName)
		await this.sumFeesTotalOnYears(currentAccountRecId, this.state.certificateProductName)
		await this.render();
	};


	showModal = () => {
		this.setState({
		  isModalOpen: true,
		});
	};
	
	handleOk = async () => {
		const partners = await axios.get(baseURL+"/advisoraccounts?advisorId=" + this.state.advisorId + '&partnerSin=' + this.state.selectedPartnerSin)
		this.setState({
			isModalOpen: false,
		});

		await this.setState({selectedYear: moment().dayOfYear(1).format(dateFormat)})
		await this.sumFeesTotalOnYears(partners.data[0].recId, this.state.certificateProductName)
		await this.getAccountTrailerFeesTotal(partners.data[0].recId, '')
		await this.sumFeesTotalOnYears(partners.data[0].recId, this.state.certificateProductName)
		
		await this.setState({partner: partners.data[0], partnersData: partners.data, currentAccountRecId: partners.data[0].recId, displayAccountNum: partners.data[0].account, displayAdvisorType: partners.data[0].advisorType })

		const commQuery = '?partnerId='+partners.data[0].recId+'&productName='+''
		let cert_data = await axios.get(baseURL+"/commissiondetails"+commQuery)
		this.setState({certData: cert_data.data});
		await this.countTotalAccountsValue(partners.data.map((partnerData:any)=>(partnerData.recId)))
		await this.render()
	}

	handleCancel =() => {
		this.setState({
		  isModalOpen: false,
		});
	};

	handleSearch = (value) => {
		this.setState({ searchText: value });
		this.render();
	  };

	  renderTable = () => {
		const columns = [
			{
			  title: 'First Name',
			  dataIndex: 'firstName',
			},
			{
				title: 'Last Name',
				dataIndex: 'lastName',
			},
 			{
			  title: 'Phone',
			  dataIndex: 'phone',
			},
 			{
				title: 'Email',
				dataIndex: 'emailAddress',
			},
			{
				title: 'Total Drip',
				dataIndex: 'totalDrip',
			},
			{
				title: 'Total Value',
				dataIndex: 'totalValue',
			},
		  ];
		  const rowSelection = {
			onChange: (selectedRowKey: any, selectedRow: any) => {
			this.setState({selectedPartnerSin: selectedRow[0].tin});
			}
			};
			
		  const data = this.state.clientList
		  const filteredData = data.filter((item) =>
			// replace 'name' with the property you want to search by
			item.lastName.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
			item.firstName.toLowerCase().includes(this.state.searchText.toLowerCase()) 
		  );
		  return <Table columns={columns} dataSource={filteredData} size="small" rowSelection={{type: "radio",...rowSelection}} />;
		};

	 handleMonthSelect = async (value: string) => {
		await this.setState({selectedCommMonth: value})
		const advisorData = await axios.get(baseURL+'/advisorcommission?email=' + this.state.isAuthenticated[1] + '&theMonth=' + this.state.selectedCommMonth + '&theYear=2024') //get emd table
		await this.setState({ certDataAdvisor: advisorData.data})
		const totalValue = await axios.get(baseURL+'/advisorcommissiontotalbymonth?email=' + this.state.isAuthenticated[1] + '&theMonth=' + this.state.selectedCommMonth + '&theYear=2024')
		await this.setState({currentMonthFeesTotal: totalValue.data})
		this.render()
	  };

	render() {

return (       
          <div id="portfolio">	
				<div style={{textAlign:'center'}}>
				<br/>
						<Button type="primary" onClick={this.showModal}>
							Client List
						</Button>
				</div>
				<Modal title={<div style={{textAlign: 'center', fontSize: "23px", fontWeight: 'bold'}}>Client List</div>}  visible={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel} width={1000}>
					<Input.Search placeholder="Name Search" onSearch={this.handleSearch} style={{ marginBottom: 16 }}/>
	{/* 				<Table columns={columns} dataSource={data} size="small" rowSelection={{type: "radio",...rowSelection}} />
	 */}				{this.renderTable()}
				</Modal>	
				<div style={{textAlign:'center'}}>
					<br/>
					<strong>Total Clients: {this.state.totalClient} &nbsp;&nbsp;&nbsp;&nbsp; Total Assets: {this.state.totalAsset}</strong>
					<br/>
				</div>

				<Descriptions title={<span style={{ fontSize: "20px" }}>Client</span>} layout="horizontal" >
					<Descriptions.Item label="Email">{this.state.partner.emailAddress || ''} </Descriptions.Item>
					<Descriptions.Item label="First Name">{this.state.partner.firstName || ''}</Descriptions.Item>
					<Descriptions.Item label="Last Name">{this.state.partner.lastName || ''}</Descriptions.Item>
					<Descriptions.Item label="Full Name">{this.state.partner.fullName || ''}</Descriptions.Item>
					<Descriptions.Item label="Phone">{this.state.partner.phoneHome || ''}</Descriptions.Item>
 				</Descriptions>
 				<Title level={4} style={{ marginTop: '20px' }}>Portfolio</Title>
	            <div className="ant-table " style={{ display: 'flex', flex: 'auto', }}>
	                  <table>
	                    <thead className="ant-table-thead">
	                      <tr>
							<th>Account</th>
	                        <th>Shares(S1) Balance</th>
							<th>Shares(S2) Balance</th>
	                        <th>Account Value</th> 
							<th>Commission/Trailer Fees(From {moment().subtract(9, "years").year()})</th> 
	                      </tr>
	                    </thead>  
	                  <tbody className="ant-table-tbody">
	                      {
								this.state.totalAccountValue.map((value:any, i:number)=>(
								  <tr key={i}>
									<td><a onClick={this.handleTagChange} id={value.partnerInfo.recId}>{value.partnerInfo.category} ({value.partnerInfo.account})</a></td>
									<td>{Number(value.total_share_S1_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
									<td>{Number(value.total_share_S2_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
									<td>${Number(value.accountTotal).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
									<td><strong>${value.totalAccountTrailerFees}</strong></td>
 								  </tr>
								))
						  }
 	                            <tr>
									 <td></td>
									<td></td>
									<td></td>
									 <td><strong>Total Value: ${this.state.totalAccountsValue}</strong></td>
									 <td><strong>Total Fees: ${this.state.totalAccountsTrailerFees}</strong></td>
								</tr>           	                    
	                    </tbody> 
	                  </table>
	            </div> 

				<div style={{marginTop: '40px'}}>
{/* 					<Form name="time_related_controls" onClick={this.onClickCertYear} onFinish={this.onFinishCert} layout="inline"
 */}					<Form name="time_related_controls" onClick={this.onClickCertYear}  layout="inline"
					>
					<Form.Item  name="product-select" >
						<Radio.Group>
						<Radio.Button value="GINKGO-S1">GINKGO-S1</Radio.Button>
						<Radio.Button value="GINKGO-S2">GINKGO-S2</Radio.Button>
						<Radio.Button value="All">All</Radio.Button>
						</Radio.Group>
					</Form.Item>			
					<Form.Item  name="year-select" >
						<Radio.Group style={{ marginLeft: '433px' }}>
						<Radio.Button value="firstYear">{moment().subtract(4, 'years').year()}</Radio.Button>
						<Radio.Button value="secondYears">{moment().subtract(3, 'years').year()}</Radio.Button>
						<Radio.Button value="thirdYears">{moment().subtract(2, 'years').year()}</Radio.Button>
						<Radio.Button value="fourthYears">{moment().subtract(1, 'years').year()}</Radio.Button>
						<Radio.Button value="fifthYears">{moment().year()}</Radio.Button>
						</Radio.Group>
					</Form.Item>	
									
					</Form>
				</div>
				
				<Title level={4} >Commission/Trailer Fees History (Account:{this.state.displayAccountNum}){(this.state.displayAdvisorType) === 'IBP' ? '*IBP' : ''}</Title>

	            <div className="ant-table">
	                  <table>
	                    <thead className="ant-table-thead">
	                      <tr>
	                        <th>Certificate #</th>
							<th>Status</th>
							<th>Product</th>
	                        <th>Investment Amount</th>
	                        <th>Date Issued</th>       
							<th>Transaction Date</th>       
	                        <th>{moment(this.state.selectedYear).subtract(4, 'years').year()}</th>
	                        <th>{moment(this.state.selectedYear).subtract(3, 'years').year()}</th>
	                        <th>{moment(this.state.selectedYear).subtract(2, 'years').year()}</th>
	                        <th>{moment(this.state.selectedYear).subtract(1, 'years').year()}</th>
	                        <th>{moment(this.state.selectedYear).year()}</th>
	                      </tr>
	                    </thead>  
	                  <tbody className="ant-table-tbody">
	                      { this.state.certData.length !== 0? 
	                        this.state.certData.map((cert:any, i:any)=>(                                                
	                            <tr className="ant-table-row" key={i}	                                            
								>                            
								<td>{cert.certificate.number? cert.certificate.number : ''}</td>
								<td>{cert.certificate.status === 0? 
								<><td>Active</td></>
								: cert.certificate.status === 1? 
								<><td>Redeemed</td></>
								: cert.certificate.status === 2?
								<><td>Transferred</td></>
								: ''}</td>
								<td>{cert.partnershipaccount? cert.partnershipaccount : ''}</td>
								<td>{cert.transactionamount? cert.transactionamount:''}</td>
								<td>{cert.certificate.issuedDate?moment(cert.certificate.issuedDate, DB_dateFormat).format(dateFormat):''}</td>
								<td>{cert.certificate.transferDate?moment(cert.certificate.transferDate, DB_dateFormat).format(dateFormat):''}</td>
								{ moment(this.state.selectedYear).year() === moment().year()? 
								<><td>{cert.yearB4}</td><td>{cert.yearB3}</td><td>{cert.yearB2}</td><td>{cert.yearB1}</td><td>{cert.yearB0}</td></>
								: moment(this.state.selectedYear).year() === moment().subtract(1,'year').year()? 
								<><td>{cert.yearB5}</td><td>{cert.yearB4}</td><td>{cert.yearB3}</td><td>{cert.yearB2}</td><td>{cert.yearB1}</td></>
								: moment(this.state.selectedYear).year() === moment().subtract(2,'year').year()?
								<><td>{cert.yearB6}</td><td>{cert.yearB5}</td><td>{cert.yearB4}</td><td>{cert.yearB3}</td><td>{cert.yearB2}</td></>
								: moment(this.state.selectedYear).year() === moment().subtract(3,'year').year()?
								<><td>{cert.yearB7}</td><td>{cert.yearB6}</td><td>{cert.yearB5}</td><td>{cert.yearB4}</td><td>{cert.yearB3}</td></>
								: moment(this.state.selectedYear).year() === moment().subtract(4,'year').year()?
								<><td>{cert.yearB8}</td><td>{cert.yearB7}</td><td>{cert.yearB6}</td><td>{cert.yearB5}</td><td>{cert.yearB4}</td></>
								: <><td></td><td></td><td></td><td></td><td></td></>}
 								</tr>   	                                              
	                        ))
							: ''}
							<tr>
							<td></td>
							<td></td>
							<td></td>
						   <td></td>
						   <td></td>
						   <td></td>
						   {this.state.selectedYearCommTotal.length !==0 ? 
						   this.state.selectedYearCommTotal.map((total:any, i:any)=>(					   		
							<td><strong>Total: {total} </strong></td>))
							: ''}
							</tr>           	                    
	                    </tbody>
	                  </table>
	            </div> 


<Title level={4} >Commission And Trailer Fees Payable</Title>
<Select
      defaultValue={moment().subtract(1, 'months').format('MMMM')}
      style={{ width: 120 }}
       onChange={this.handleMonthSelect}
       options={[
        {
          value: '0',
          label: 'January',
        },
        {
          value: '1',
          label: 'Febuary',
        },
        {
          value: '2',
          label: 'March',
        },
        {
          value: '3',
          label: 'April',
        },
        {
			value: '4',
			label: 'May',
		  },
		  {
			value: '5',
			label: 'June',
		  },
		  {
			value: '6',
			label: 'July',
		  },
		  {
			value: '7',
			label: 'Augest',
		  },
		  {
			value: '8',
			label: 'September',
		  },
		  {
			value: '9',
			label: 'October',
		  },
		  {
			value: '10',
			label: 'November',
		  },
		  {
			value: '11',
			label: 'December',
		  },
	]}
    />
	<br/>
	<br/>

<div className="ant-table">
	  <table>
		<thead className="ant-table-thead">
		  <tr>
		  <th>Certificate #</th>
		  <th>Account #</th>
		  <th>Account Name</th>
{/* 		  <th>Status</th>       
 */}			<th>Product</th>
			<th>Investment Amount</th>
			<th>Date Issued</th>    
			<th>Commission/Trailer Fees</th>            
		  </tr>
		</thead>  
	  <tbody className="ant-table-tbody">
	                      { this.state.certDataAdvisor.length !== 0? 
	                        this.state.certDataAdvisor.map((cert:any, i:any)=>(    
	                            <tr className="ant-table-row" key={i}	                                            
								>                            
								<td>{cert.certNumber}</td>
								<td>{cert.accountNumber}</td>
								<td>{cert.accountName}</td>
 								<td>{cert.product}</td>
								<td>{cert.investmentAmount}</td>
								<td>{cert.issuedDate}</td> 
								{cert.payType === 'IBP'? 
				 				<td>{cert.amountPaid}*</td>
							: <td>{cert.amountPaid}</td>}
				{/* 				<td>{cert.yearB1}</td>
 				*/}				</tr>   	    
								))
							: ''}
							<td></td>
							<td></td>
{/* 							<td></td>
 */}						   <td></td>
						   <td></td>
						   <td></td>
						   <td></td>
	{/* 						<td><strong>Total: ${Number(this.state.currentMonthFeesTotal.data).toFixed(2)} </strong></td>
	 */}						<td><strong>Total: {this.state.currentMonthFeesTotal} </strong></td>
                                          
 
 		</tbody>
	  </table>
</div> 

 
          </div>          	    
	  )
}
}

export default withRouter(Portfolio);
